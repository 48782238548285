
function Home() {
  return (
    <>
        Here is where you can find FREElancers all over the world
    </>

  );
}

export default Home;
