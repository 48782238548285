import { Routes, Route, Navigate } from 'react-router-dom';
import Home from './views/home';
import Profile from './views/profile';

function App() {
  return (
    <>
         <Routes>
            <Route path="/profile/kevin-lee" element={<Profile />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/home" element={<Home />} />
            <Route path="/" element={<Navigate to="/profile" />} />
         </Routes>
      </>

  );
}

export default App;
