import { useEffect } from "react";

function Profile() {

    useEffect(() => {
        const script = document.createElement("script");
        script.src = "/assets/js/custom-scripts.js";
        script.async = true;
        script.onload = () => {};

        document.body.appendChild(script);
    });

    return (
        <>
            {/* Start Loader */}
            <div className="section-loader">
                <div className="loader">
                    <div></div>
                    <div></div> 
                </div>
            </div>
            {/* End Loader */}
            
            {/*
            ===================
            NAVIGATION
            ===================
            */}
            <header className="black-bg mh-header mh-fixed-nav nav-scroll mh-xs-mobile-nav" id="mh-header">
                <div className="overlay"></div>
                <div className="container">
                    <div className="row">
                        <nav className="navbar navbar-expand-lg mh-nav nav-btn">
                            <a className="navbar-brand" href="#">
                                <img src="assets/images/logo.png" alt="Logo" className="img-fluid" />
                                {/* <h2>Maha</h2> */}
                            </a>
                            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon icon"></span>
                            </button>
                        
                            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                <ul className="navbar-nav mr-0 ml-auto">
                                    <li className="nav-item active">
                                        <a className="nav-link" href="#mh-home">Home</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="#mh-about">About</a>
                                    </li>
                                    <li className="nav-item">
                                    <a className="nav-link" href="#mh-skills">Skills</a>
                                    </li>                                
                                    <li className="nav-item">
                                    <a className="nav-link" href="#mh-experience">Experiences</a>
                                    </li>                                
                                    <li className="nav-item">
                                        <a className="nav-link" href="#mh-portfolio">Portfolio</a>
                                    </li>                               
                                    <li className="nav-item">
                                        <a className="nav-link" href="#mh-pricing">Pricing</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="#mh-blog">Blog</a>
                                    </li>
                                    <li className="nav-item">
                                    <a className="nav-link" href="#mh-contact">Contact</a>
                                    </li>
                                </ul>
                            </div>
                        </nav>
                    </div>
                </div>
            </header>

            {/*
            ===================
            Home
            ===================
            */}
            <section className="mh-home image-bg home-2-img" id="mh-home">
                <div className="img-foverlay img-color-overlay">
                    <div className="container">
                        <div className="row section-separator xs-column-reverse vertical-middle-content home-padding">
                            <div className="col-sm-6">
                                <div className="mh-header-info">
                                    <div className="mh-promo wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.1s">
                                        <span>Hello I'm</span>
                                    </div>
                                    
                                    <h2 className="wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.2s">Kevin Lee</h2>
                                    <h4 className="wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.3s">Full-Stack Developer |<br/> Specializing in DevOps & Backend</h4>
                                    
                                    <ul>
                                        <li className="wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.4s"><i className="fa fa-envelope"></i><a href="mailto:">kevinleeonx@gmail.com</a></li>
                                        <li className="wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.5s"><i className="fa fa-phone"></i><a href="callto:+18036278152">(803) 627-8152</a></li>
                                        <li className="wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.6s"><i className="fa fa-map-marker"></i><address>Chula Vista, California</address></li>
                                    </ul>
                                    {/*
                                    <ul className="social-icon wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.7s">
                                        <li><a href=""><i className="fa fa-facebook"></i></a></li>
                                        <li><a href=""><i className="fa fa-twitter"></i></a></li>
                                        <li><a href=""><i className="fa fa-github"></i></a></li>
                                        <li><a href=""><i className="fa fa-dribbble"></i></a></li>
                                    </ul>
                                    */}
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="hero-img wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.6s">
                                    <div className="img-border">
                                        <img src="assets/images/profile1.png" alt=""  className="img-fluid" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            
            {/*
            ===================
            ABOUT
            ===================
            */}
            <section className="mh-about" id="mh-about">
                <div className="container">
                    <div className="row section-separator">
                        <div className="col-sm-12 col-md-6">
                            <div className="mh-about-img shadow-2 wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.4s">
                                <img src="assets/images/ab-img.png" alt="" className="img-fluid" />
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6">
                            <div className="mh-about-inner">
                            <h2 className="wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.1s">About Me</h2>
                            <p className="wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.2s">
                            Full-stack web developer with over 10 years of experience. <br/>
                            Proven track record of working with startup companies to accelerate growth and deliver quality software solutions. <br/>
                            Skilled in agile development, team leadership, and cost-effective project management. <br/>
                            Available for freelance or full-time positions requiring agile collaboration and timely delivery. <br/>
                            My practice area :
                            </p>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="mh-about-tag mh-about-tag-2 wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.3s">
                                <ul>
                                    <li><span>JavaScript</span></li>
                                    <li><span>TypeScript</span></li>
                                    <li><span>PHP</span></li>
                                    <li><span>Python</span></li>
                                    <li><span>Java</span></li>
                                    <li><span>Golang</span></li>
                                    <li><span>Ruby</span></li>
                                    <li><span>C#</span></li>
                                    <li><span>Node.js</span></li>
                                    <li><span>React.js</span></li>
                                    <li><span>Laravel</span></li>
                                    <li><span>Spring</span></li>
                                    <li><span>Angular</span></li>
                                    <li><span>Ruby on Rails</span></li>
                                    <li><span>ASP.NET Core</span></li>
                                    <li><span>GCP</span></li>
                                    <li><span>AWS</span></li>
                                    <li><span>Azure</span></li>
                                    <li><span>Alibaba Cloud</span></li>
                                    <li><span>M2X IoT</span></li>
                                    <li><span>MySQL</span></li>
                                    <li><span>MongoDB</span></li>
                                    <li><span>PostgreSQL</span></li>
                                    <li><span>MS-SQL</span></li>
                                    <li><span>NoSQL</span></li>
                                    <li><span>DevOps</span></li>
                                    <li><span>CI/CD</span></li>
                                    <li><span>IaC</span></li>
                                    <li><span>Terraform</span></li>
                                    <li><span>Kubernetes</span></li>
                                    <li><span>Docker</span></li>
                                    <li><span>CircleCI</span></li>
                                    <li><span>Jenkins</span></li>
                                </ul>
                                <a href="" className="btn btn-fill wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.4s">Downlaod CV <i className="fa fa-download"></i></a>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </section>
            
            {/*
            ===================
            SERVICE
            ===================
            */}
            <section className="mh-service">
                <div className="container">
                    <div className="row section-separator">
                        <div className="col-sm-12 text-center section-title wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.2s">
                            <h2>What I do</h2>
                        </div>
                        <div className="col-sm-4">
                            <div className="mh-service-item shadow-1 dark-bg wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.3s">
                                <i className="fa fa-bullseye purple-color"></i>
                                <h3>UI Design</h3>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit,
                                    sed diam nonummy nibh euismod tincidunt ut laoreet dolore
                                    magna aliquam erat volutpat.
                                </p>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="mh-service-item shadow-1 dark-bg wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.5s">
                                <i className="fa fa-code iron-color"></i>
                                <h3>Web Development</h3>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit,
                                    sed diam nonummy nibh euismod tincidunt ut laoreet dolore
                                    magna aliquam erat volutpat.
                                </p>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="mh-service-item shadow-1 dark-bg wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.7s">
                                <i className="fa fa-object-ungroup sky-color"></i>
                                <h3>App Development</h3>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit,
                                    sed diam nonummy nibh euismod tincidunt ut laoreet dolore
                                    magna aliquam erat volutpat.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            
            {/*
            ===================
            FEATURE PROJECTS
            ===================
            */}
            <section className="mh-featured-project image-bg featured-img-one">
                <div className="img-color-overlay">
                    <div className="container">
                        <div className="row section-separator">
                            <div className="section-title col-sm-12">
                                <h3>Featured Projects</h3>
                            </div>
                            <div className="col-sm-12">
                                <div className="mh-single-project-slide-by-side row">
                                    {/* Project Items */}
                                    <div className="col-sm-12 mh-featured-item">
                                        <div className="row">
                                            <div className="col-sm-7">
                                                <div className="mh-featured-project-img shadow-2 wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.2s">
                                                    <img src="assets/images/p-2.png" alt="" className="img-fluid" />
                                                </div>
                                            </div>
                                            <div className="col-sm-5">
                                                <div className="mh-featured-project-content">
                                                    <h4 className="project-category wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.4s">Web Design</h4>
                                                    <h2 className="wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.5s">Wrap</h2>
                                                    <span className="wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.6s">Design & Development</span>
                                                    <p className="wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.7s">Stamp is a clean and elegant Multipurpose Landing Page Template. 
                                                    It will fit perfectly for Startup, Web App or any type of Web Services.
                                                    It has 4 background styles with 6 homepage styles. 6 pre-defined color scheme. 
                                                    All variations are organized separately so you can use / customize the template very easily.</p>
                                                    <a href="" className="btn btn-fill wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.7s">View Details</a>
                                                    <div className="mh-testimonial mh-project-testimonial wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.9s">
                                                        <blockquote>
                                                            <q>Excellent Template - suits my needs perfectly whilst allowing me to learn some new technology first hand.</q>
                                                            <cite>- Shane Kavanagh</cite>
                                                        </blockquote>
                                                        <blockquote>
                                                            <q>Creative Template - suits my needs perfectly whilst allowing me to learn some new technology first hand.</q>
                                                            <cite>- Shane Kavanagh</cite>
                                                        </blockquote>
                                                        <blockquote>
                                                            <q>Organize Code - suits my needs perfectly whilst allowing me to learn some new technology first hand.</q>
                                                            <cite>- Shane Kavanagh</cite>
                                                        </blockquote>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Project Items */}
                                    <div className="col-sm-12 mh-featured-item">
                                        <div className="row">
                                            <div className="col-sm-7">
                                                <div className="mh-featured-project-img shadow-2 wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.2s">
                                                    <img src="assets/images/p-2.png" alt="" className="img-fluid" />
                                                </div>
                                            </div>
                                            <div className="col-sm-5">
                                                <div className="mh-featured-project-content">
                                                    <h4 className="project-category wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.4s">Web Design</h4>
                                                    <h2 className="wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.5s">Wrap</h2>
                                                    <span className="wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.6s">Design & Development</span>
                                                    <p className="wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.7s">Stamp is a clean and elegant Multipurpose Landing Page Template. 
                                                    It will fit perfectly for Startup, Web App or any type of Web Services.
                                                    It has 4 background styles with 6 homepage styles. 6 pre-defined color scheme. 
                                                    All variations are organized separately so you can use / customize the template very easily.</p>
                                                    <a href="" className="btn btn-fill wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.7s">View Details</a>
                                                    <div className="mh-testimonial mh-project-testimonial wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.9s">
                                                        <blockquote>
                                                            <q>Excellent Template - suits my needs perfectly whilst allowing me to learn some new technology first hand.</q>
                                                            <cite>- Shane Kavanagh</cite>
                                                        </blockquote>
                                                        <blockquote>
                                                            <q>Creative Template - suits my needs perfectly whilst allowing me to learn some new technology first hand.</q>
                                                            <cite>- Shane Kavanagh</cite>
                                                        </blockquote>
                                                        <blockquote>
                                                            <q>Organize Code - suits my needs perfectly whilst allowing me to learn some new technology first hand.</q>
                                                            <cite>- Shane Kavanagh</cite>
                                                        </blockquote>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Project Items */}
                                    <div className="col-sm-12 mh-featured-item">
                                        <div className="row">
                                            <div className="col-sm-7">
                                                <div className="mh-featured-project-img shadow-2 wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.2s">
                                                    <img src="assets/images/p-2.png" alt="" className="img-fluid" />
                                                </div>
                                            </div>
                                            <div className="col-sm-5">
                                                <div className="mh-featured-project-content">
                                                    <h4 className="project-category wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.4s">Web Design</h4>
                                                    <h2 className="wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.5s">Wrap</h2>
                                                    <span className="wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.6s">Design & Development</span>
                                                    <p className="wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.7s">Stamp is a clean and elegant Multipurpose Landing Page Template. 
                                                    It will fit perfectly for Startup, Web App or any type of Web Services.
                                                    It has 4 background styles with 6 homepage styles. 6 pre-defined color scheme. 
                                                    All variations are organized separately so you can use / customize the template very easily.</p>
                                                    <a href="" className="btn btn-fill wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.7s">View Details</a>
                                                    <div className="mh-testimonial mh-project-testimonial wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.9s">
                                                        <blockquote>
                                                            <q>Excellent Template - suits my needs perfectly whilst allowing me to learn some new technology first hand.</q>
                                                            <cite>- Shane Kavanagh</cite>
                                                        </blockquote>
                                                        <blockquote>
                                                            <q>Creative Template - suits my needs perfectly whilst allowing me to learn some new technology first hand.</q>
                                                            <cite>- Shane Kavanagh</cite>
                                                        </blockquote>
                                                        <blockquote>
                                                            <q>Organize Code - suits my needs perfectly whilst allowing me to learn some new technology first hand.</q>
                                                            <cite>- Shane Kavanagh</cite>
                                                        </blockquote>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> {/* End: .row */}
                    </div>
                </div>
            </section>  
            
            {/*
            ===================
            SKILLS
            ===================
            */}
            <section className="mh-skills" id="mh-skills">
                <div className="home-v-img">
                    <div className="container">
                        <div className="row section-separator">
                            <div className="section-title text-center col-sm-12">
                                {/*<h2>Skills</h2>*/}
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <div className="mh-skills-inner">
                                    <div className="mh-professional-skill wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.3s">
                                        <h3>Technical Skills</h3>
                                        <div className="each-skills">
                                            <div className="candidatos">
                                                <div className="parcial">
                                                    <div className="info">
                                                        <div className="nome">Javascript</div>
                                                        <div className="percentagem-num">86%</div>
                                                    </div>
                                                    <div className="progressBar">
                                                        <div className="percentagem" style={{width: "86%"}}></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="candidatos">
                                                <div className="parcial">
                                                    <div className="info">
                                                        <div className="nome">Java</div>
                                                        <div className="percentagem-num">46%</div>
                                                    </div>
                                                    <div className="progressBar">
                                                        <div className="percentagem" style={{width: "46%"}}></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="candidatos">
                                                <div className="parcial">
                                                    <div className="info">
                                                        <div className="nome">Python</div>
                                                        <div className="percentagem-num">38%</div>
                                                    </div>
                                                    <div className="progressBar">
                                                        <div className="percentagem" style={{width: "38%"}}></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="candidatos">
                                                <div className="parcial">
                                                    <div className="info">
                                                        <div className="nome">PHP</div>
                                                        <div className="percentagem-num">17%</div>
                                                    </div>
                                                    <div className="progressBar">
                                                        <div className="percentagem" style={{width: "17%"}}></div>
                                                    </div>
                                                </div>
                                            </div>                                    
                                            
                                            <div className="candidatos">
                                                <div className="parcial">
                                                    <div className="info">
                                                        <div className="nome">Python</div>
                                                        <div className="percentagem-num">38%</div>
                                                    </div>
                                                    <div className="progressBar">
                                                        <div className="percentagem" style={{width: "38%"}}></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="candidatos">
                                                <div className="parcial">
                                                    <div className="info">
                                                        <div className="nome">PHP</div>
                                                        <div className="percentagem-num">17%</div>
                                                    </div>
                                                    <div className="progressBar">
                                                        <div className="percentagem" style={{width: "17%"}}></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <div className="mh-professional-skills wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.5s">
                                    <h3>Professional Skills</h3>
                                    <ul className="mh-professional-progress">
                                        <li>
                                            <div className="mh-progress mh-progress-circle" data-progress="95"></div>
                                            <div className="pr-skill-name">Communication</div>
                                        </li>
                                        <li>
                                            <div className="mh-progress mh-progress-circle" data-progress="55"></div> 
                                            <div className="pr-skill-name">Team Work</div>
                                        </li>
                                        <li>
                                            <div className="mh-progress mh-progress-circle" data-progress="86"></div>
                                            <div className="pr-skill-name">Project Management</div>
                                        </li> 
                                        <li>
                                            <div className="mh-progress mh-progress-circle" data-progress="60"></div>
                                            <div className="pr-skill-name">Creativity</div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            
            {/*
            ===================
            EXPERIENCES
            ===================
            */}
            <section className="mh-experince image-bg featured-img-one" id="mh-experience">
                <div className="img-color-overlay">
                    <div className="container">
                        <div className="row section-separator">
                            <div className="col-sm-12 col-md-6">
                                <div className="mh-education">
                                    <h3 className="wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.2s">Education</h3>
                                    <div className="mh-education-deatils">
                                        {/* Education Institutes*/}
                                        <div className="mh-education-item dark-bg wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.3s">
                                            <h4>Art & Multimedia From <a href="">Oxford University</a></h4>
                                            <div className="mh-eduyear">2005-2008</div>
                                            <p>It is a long established fact that a reader will be distracted by the readable content of a 
                                            page when looking at its layout. The point of using  Lorem Ipsum </p>
                                        </div>                                
                                        {/* Education Institutes*/}
                                        <div className="mh-education-item dark-bg wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.5s">
                                            <h4>Art & Multimedia From <a href="">Oxford University</a></h4>
                                            <div className="mh-eduyear">2005-2008</div>
                                            <p>It is a long established fact that a reader will be distracted by the readable content of a 
                                            page when looking at its layout. The point of using  Lorem Ipsum </p>
                                        </div>                                
                                        {/* Education Institutes*/}
                                        <div className="mh-education-item dark-bg wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.6s">
                                            <h4>Art & Multimedia From <a href="">Oxford University</a></h4>
                                            <div className="mh-eduyear">2005-2008</div>
                                            <p>It is a long established fact that a reader will be distracted by the readable content of a 
                                            page when looking at its layout. The point of using L orem Ipsum </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <div className="mh-work">
                                    <h3>Work Experience</h3>
                                    <div className="mh-experience-deatils">
                                        {/* Education Institutes*/}
                                        <div className="mh-work-item dark-bg wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.4s">
                                            <h4>UI/UX Designer <a href="">IronSketch</a></h4>
                                            <div className="mh-eduyear">2005-2008</div>
                                            <span>Responsibility :</span>
                                            <ul className="work-responsibility">
                                                <li><i className="fa fa-circle"></i>Validate CSS</li>
                                                <li><i className="fa fa-circle"></i>Project Management</li>
                                            </ul>
                                        </div>                                
                                        {/* Education Institutes*/}
                                        <div className="mh-work-item dark-bg wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.6s">
                                            <h4>Art & Multimedia From <a href="">Oxford University</a></h4>
                                            <div className="mh-eduyear">2005-2008</div>
                                            <span>Responsibility :</span>
                                            <ul className="work-responsibility">
                                                <li><i className="fa fa-circle"></i>Validate CSS</li>
                                                <li><i className="fa fa-circle"></i>Project Management</li>
                                            </ul>
                                        </div>                                
                                        {/* Education Institutes*/}
                                        <div className="mh-work-item dark-bg wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.7s">
                                            <h4>Art & Multimedia From <a href="">Oxford University</a></h4>
                                            <div className="mh-eduyear">2005-2008</div>
                                            <span>Responsibility :</span>
                                            <ul className="work-responsibility">
                                                <li><i className="fa fa-circle"></i>Validate CSS</li>
                                                <li><i className="fa fa-circle"></i>Project Management</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>       
            
            {/*
            ===================
            PORTFOLIO
            ===================
            */}
            <section className="mh-portfolio" id="mh-portfolio">
                <div className="container">
                    <div className="row section-separator">
                        <div className="section-title col-sm-12 wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.1s">
                            <h3>Recent Portfolio</h3>
                        </div>
                        <div className="part col-sm-12">
                            <div className="portfolio-nav col-sm-12" id="filter-button">
                                <ul>
                                    <li data-filter="*" className="current wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.1s"> <span>All Categories</span></li>
                                    <li data-filter=".user-interface" className="wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.2s"><span>Web Design</span></li>
                                    <li data-filter=".branding" className="wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.3s"><span>Branding</span></li>
                                    <li data-filter=".mockup" className="wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.4s"><span>Mockups</span></li>
                                    <li data-filter=".ui" className="wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.5s"><span>Photography</span></li>
                                </ul>
                            </div>
                            <div className="mh-project-gallery col-sm-12 wow fadeInUp" id="project-gallery" data-wow-duration="0.8s" data-wow-delay="0.5s">
                                <div className="portfolioContainer row">
                                    <div className="grid-item col-md-4 col-sm-6 col-xs-12 user-interface">
                                        <figure>
                                            <img src="assets/images/portfolio/g1.jpg" alt="img04" />
                                            <figcaption className="fig-caption">
                                                <i className="fa fa-search"></i>
                                                <h5 className="title">Creative Design</h5>
                                                <span className="sub-title">Photograpy</span>
                                                <a data-fancybox data-src="#mh"></a>
                                            </figcaption>
                                        </figure>
                                    </div>
                                    <div className="grid-item col-md-4 col-sm-6 col-xs-12 ui mockup">
                                        <figure>
                                            <img src="assets/images/portfolio/g2.png" alt="img04" />
                                            <figcaption className="fig-caption">
                                                <i className="fa fa-search"></i>
                                                <h5 className="title">Creative Design</h5>
                                                <span className="sub-title">Photograpy</span>
                                                <a href="assets/images/portfolio/g2.png" data-fancybox data-src="#mh"></a>
                                            </figcaption>
                                        </figure>
                                    </div>
                                    <div className="grid-item col-md-4 col-sm-6 col-xs-12 user-interface">
                                        <figure>
                                            <img src="assets/images/portfolio/g3.png" alt="img04" />
                                            <figcaption className="fig-caption">
                                                <i className="fa fa-search"></i>
                                                <h5 className="title">Creative Design</h5>
                                                <span className="sub-title">Photograpy</span>
                                                <a href="assets/images/portfolio/g3.png" data-fancybox data-src="#mh"></a>
                                            </figcaption>
                                        </figure>
                                    </div>
                                    <div className="grid-item col-md-4 col-sm-6 col-xs-12 branding">
                                        <figure>
                                            <img src="assets/images/portfolio/g5.png" alt="img04" />
                                            <figcaption className="fig-caption">
                                                <i className="fa fa-search"></i>
                                                <h5 className="title">Creative Design</h5>
                                                <span className="sub-title">Photograpy</span>
                                                <a href="assets/images/portfolio/g5.png" data-fancybox data-src="#mh"></a>
                                            </figcaption>
                                        </figure>
                                    </div>
                                    <div className="grid-item col-md-4 col-sm-6 col-xs-12 user-interface">
                                        <figure>
                                            <img src="assets/images/portfolio/g4.png" alt="img04" />
                                            <figcaption className="fig-caption">
                                                <i className="fa fa-search"></i>
                                                <h5 className="title">Creative Design</h5>
                                                <span className="sub-title">Photograpy</span>
                                                <a href="assets/images/portfolio/g4.png" data-fancybox data-src="#mh"></a>
                                            </figcaption>
                                        </figure>
                                    </div>
                                    <div className="grid-item col-md-4 col-sm-6 col-xs-12 branding">
                                        <figure>
                                            <img src="assets/images/portfolio/g6.png" alt="img04" />
                                            <figcaption className="fig-caption">
                                                <i className="fa fa-search"></i>
                                                <h5 className="title">Creative Design</h5>
                                                <span className="sub-title">Photograpy</span>
                                                <a href="assets/images/portfolio/g6.png" data-fancybox data-src="#mh"></a>
                                            </figcaption>
                                        </figure>
                                    </div>
                                    <div className="grid-item col-md-4 col-sm-6 col-xs-12 branding">
                                        <figure>
                                            <img src="assets/images/portfolio/g8.png" alt="img04" />
                                            <figcaption className="fig-caption">
                                                <i className="fa fa-search"></i>
                                                <h5 className="title">Creative Design</h5>
                                                <span className="sub-title">Photograpy</span>
                                                <a href="assets/images/portfolio/g8.png" data-fancybox data-src="#mh"></a>
                                            </figcaption>
                                        </figure>
                                    </div>
                                    <div className="grid-item col-md-4 col-sm-6 col-xs-12 ui">
                                        <figure>
                                            <img src="assets/images/portfolio/g9.png" alt="img04" />
                                            <figcaption className="fig-caption">
                                                <i className="fa fa-search"></i>
                                                <h5 className="title">Creative Design</h5>
                                                <span className="sub-title">Photograpy</span>
                                                <a href="assets/images/portfolio/g9.png" data-fancybox data-src="#mh"></a>
                                            </figcaption>
                                        </figure>
                                    </div>
                                    <div className="grid-item col-md-4 col-sm-6 col-xs-12 branding">
                                        <figure>
                                            <img src="assets/images/portfolio/g7.jpg" alt="img04" />
                                            <figcaption className="fig-caption">
                                                <i className="fa fa-search"></i>
                                                <h5 className="title">Creative Design</h5>
                                                <span className="sub-title">Photograpy</span>
                                                <a href="assets/images/portfolio/g7.jpg" data-fancybox="gallery"></a>
                                            </figcaption>
                                        </figure>
                                    </div>
                                </div> {/* End: .grid .project-gallery */}
                            </div> {/* End: .grid .project-gallery */}
                        </div> {/* End: .part */}
                    </div> {/* End: .row */}
                </div>
                <div className="mh-portfolio-modal" id="mh">
                    <div className="container">
                        <div className="row mh-portfolio-modal-inner">
                            <div className="col-sm-5">
                                <h2>Wrap - A campanion plugin</h2>
                                <p>Wrap is a clean and elegant Multipurpose Landing Page Template. 
                                It will fit perfectly for Startup, Web App or any type of Web Services.
                                It has 4 background styles with 6 homepage styles. 6 pre-defined color scheme. 
                                All variations are organized separately so you can use / customize the template very easily.</p>       
                                
                                <p>It is a clean and elegant Multipurpose Landing Page Template. 
                                It will fit perfectly for Startup, Web App or any type of Web Services.
                                It has 4 background styles with 6 homepage styles. 6 pre-defined color scheme. 
                                All variations are organized separately so you can use / customize the template very easily.</p>      
                                <div className="mh-about-tag">
                                    <ul>
                                        <li><span>php</span></li>
                                        <li><span>html</span></li>
                                        <li><span>css</span></li>
                                        <li><span>php</span></li>
                                        <li><span>wordpress</span></li>
                                        <li><span>React</span></li>
                                        <li><span>Javascript</span></li>
                                    </ul>
                                </div>
                                <a href="" className="btn btn-fill">Live Demo</a>
                            </div>
                            <div className="col-sm-7">
                                <div className="mh-portfolio-modal-img">
                                    <img src="assets/images/pr-0.jif" alt="" className="img-fluid" />
                                    <p>All variations are organized separately so you can use / customize the template very easily.</p>    
                                    <img src="assets/images/pr-1.jif" alt="" className="img-fluid" />
                                    <p>All variations are organized separately so you can use / customize the template very easily.</p>     
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            
            {/*
            ===================
            QUATES
            ===================
            */}
            <section className="mh-quates image-bg home-2-img">
                <div className="img-color-overlay">
                    <div className="container">
                        <div className="row section-separator">
                            <div className="each-quates col-sm-12 col-md-6">
                                <h3 className="wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.2s">Interested to Work?</h3>
                                <p className="wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.4s">Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat. Mirum est notare quam littera gothica.
                                quam nunc putamus parum claram,</p>
                                <a href="#mh-contact" className="btn btn-fill wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.5s">Contact</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            
            {/*
            ===================
            PRICING
            ===================
            */}
            <section className="mh-pricing" id="mh-pricing">
                <div className="">
                    <div className="container">
                        <div className="row section-separator">
                            <div className="col-sm-12 section-title wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.2s">
                                <h3>Pricing Table</h3>
                            </div>
                            <div className="col-sm-12 col-md-4">
                                <div className="mh-pricing dark-bg shadow-2 wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.3s">
                                    <i className="fa fa-calendar"></i>
                                    <h4>Full-time work</h4>
                                    <p>I am available for full time</p>
                                    <h5>$1500</h5>
                                    <ul>
                                        <li>Web Development</li>
                                        <li>Advetising</li>
                                        <li>Game Development</li>
                                        <li>Music Writing</li>
                                    </ul>
                                    <a href="" className="btn btn-fill">Hire Me</a>
                                </div>  
                            </div>                    
                            <div className="col-sm-12 col-md-4">
                                <div className="mh-pricing dark-bg shadow-2 wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.5s">
                                    <i className="fa fa-file"></i>
                                    <h4>Fixed Price Project</h4>
                                    <p>I am available for fixed roles</p>
                                    <h5>$500</h5>
                                    <ul>
                                        <li>Web Development</li>
                                        <li>Advetising</li>
                                        <li>Game Development</li>
                                        <li>Music Writing</li>
                                    </ul>
                                    <a href="" className="btn btn-fill">Hire Me</a>
                                </div>  
                            </div>
                            <div className="col-sm-12 col-md-4">
                                <div className="mh-pricing dark-bg shadow-2 wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.7s">
                                    <i className="fa fa-hourglass"></i>
                                    <h4>Hourley work</h4>
                                    <p>I am available for Hourley projets</p>
                                    <h5>$50</h5>
                                    <ul>
                                        <li>Web Development</li>
                                        <li>Advetising</li>
                                        <li>Game Development</li>
                                        <li>Music Writing</li>
                                    </ul>
                                    <a href="" className="btn btn-fill">Hire Me</a>
                                </div>  
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            
            {/*
            ===================
            BLOG
            ===================
            */}
            <section className="mh-blog image-bg featured-img-two" id="mh-blog">
                <div className="img-color-overlay">
                    <div className="container">
                        <div className="row section-separator">
                            <div className="col-sm-12 section-title wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.2s">
                                <h3>Featured Posts</h3>
                            </div>
                            <div className="col-sm-12 col-md-4">
                                <div className="mh-blog-item dark-bg wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.3s">
                                    <img src="assets/images/b-3.png" alt="" className="img-fluid" />
                                    <div className="blog-inner">
                                        <h2><a href="blog-single.html">A life without the daily traffic jams</a></h2>
                                        <div className="mh-blog-post-info">
                                            <ul>
                                                <li><strong>Post On</strong><a href="">24.11.19</a></li>
                                                <li><strong>By</strong><a href="">ThemeSpiders</a></li>
                                            </ul>
                                        </div>
                                        <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout</p>
                                        <a href="blog-single.html">Read More</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-4">
                                <div className="mh-blog-item dark-bg wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.5s">
                                    <img src="assets/images/b-2.png" alt="" className="img-fluid" />
                                    <div className="blog-inner">
                                        <h2><a href="blog-single.html">Proportion are what’s really needed</a></h2>
                                        <div className="mh-blog-post-info">
                                            <ul>
                                                <li><strong>Post On</strong><a href="">24.11.19</a></li>
                                                <li><strong>By</strong><a href="">ThemeSpiders</a></li>
                                            </ul>
                                        </div>
                                        <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout</p>
                                        <a href="blog-single.html">Read More</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-4">
                                <div className="mh-blog-item dark-bg wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.7s">
                                    <img src="assets/images/b-1.png" alt="" className="img-fluid" />
                                    <div className="blog-inner">
                                        <h2><a href="blog-single.html">Mounts of paper work to remember the way</a></h2>
                                        <div className="mh-blog-post-info">
                                            <ul>
                                                <li><strong>Post On</strong><a href="">24.11.19</a></li>
                                                <li><strong>By</strong><a href="">ThemeSpiders</a></li>
                                            </ul>
                                        </div>
                                        <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout</p>
                                        <a href="blog-single.html">Read More</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> 
            
            {/*
            ===================
            Testimonial
            ===================
            */}
            <section className="mh-testimonial" id="mh-testimonial">
                <div className="home-v-img">
                    <div className="container">
                        <div className="row section-separator">
                            <div className="col-sm-12 section-title wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.2s">
                                <h3>Client Reviews</h3>
                            </div>
                            <div className="col-sm-12 wow fadeInUp" id="mh-client-review" data-wow-duration="0.8s" data-wow-delay="0.3s">
                                <div className="each-client-item">
                                    <div className="mh-client-item dark-bg black-shadow-1">
                                        <img src="assets/images/c-1.png" alt="" className="img-fluid" />
                                        <p>Absolute wonderful ! I am completely
                                        blown away.The very best.I was amazed
                                        at the quality</p>
                                        <h4>John Mike</h4>
                                        <span>CEO, Author.Inc</span>
                                    </div>
                                </div>
                                <div className="each-client-item">
                                    <div className="mh-client-item dark-bg black-shadow-1">
                                        <img src="assets/images/c-1.png" alt="" className="img-fluid" />
                                        <p>Absolute wonderful ! I am completely
                                        blown away.The very best.I was amazed
                                        at the quality</p>
                                        <h4>John Mike</h4>
                                        <span>CEO, Author.Inc</span>
                                    </div>
                                </div>                    
                                
                                <div className="each-client-item">
                                    <div className="mh-client-item dark-bg black-shadow-1">
                                        <img src="assets/images/c-1.png" alt="" className="img-fluid" />
                                        <p>Absolute wonderful ! I am completely
                                        blown away.The very best.I was amazed
                                        at the quality</p>
                                        <h4>John Mike</h4>
                                        <span>CEO, Author.Inc</span>
                                    </div>
                                </div>
                                <div className="each-client-item">
                                    <div className="mh-client-item dark-bg black-shadow-1">
                                        <img src="assets/images/c-1.png" alt="" className="img-fluid" />
                                        <p>Absolute wonderful ! I am completely
                                        blown away.The very best.I was amazed
                                        at the quality</p>
                                        <h4>John Mike</h4>
                                        <span>CEO, Author.Inc</span>
                                    </div>
                                </div>
                                <div className="each-client-item">
                                    <div className="mh-client-item dark-bg black-shadow-1">
                                        <img src="assets/images/c-1.png" alt="" className="img-fluid" />
                                        <p>Absolute wonderful ! I am completely
                                        blown away.The very best.I was amazed
                                        at the quality</p>
                                        <h4>John Mike</h4>
                                        <span>CEO, Author.Inc</span>
                                    </div>
                                </div>                    
                                
                                <div className="each-client-item">
                                    <div className="mh-client-item dark-bg black-shadow-1">
                                        <img src="assets/images/c-1.png" alt="" className="img-fluid" />
                                        <p>Absolute wonderful ! I am completely
                                        blown away.The very best.I was amazed
                                        at the quality</p>
                                        <h4>John Mike</h4>
                                        <span>CEO, Author.Inc</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            
            {/*
            ===================
            FOOTER 3
            ===================
            */}
            <footer className="mh-footer mh-footer-3" id="mh-contact">
                <div className="container-fluid">
                    <div className="row section-separator">
                        <div className="col-sm-12 section-title wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.2s">
                            <h3>Contact Me</h3>
                        </div>
                        <div className="map-image image-bg col-sm-12">
                            <div className="container mt-30">
                                <div className="row">
                                    <div className="col-sm-12 col-md-6 mh-footer-address">
                                        <div className="col-sm-12 xs-no-padding">
                                            <div className="mh-address-footer-item dark-bg shadow-1 media wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.2s">
                                                <div className="each-icon">
                                                    <i className="fa fa-location-arrow"></i>
                                                </div>
                                                <div className="each-info media-body">
                                                    <h4>Address</h4>
                                                    <address>
                                                        5th Avenue, 34th floor, <br/> 
                                                        New york
                                                    </address>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-12 xs-no-padding">
                                            <div className="mh-address-footer-item media dark-bg shadow-1 wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.4s">
                                                <div className="each-icon">
                                                    <i className="fa fa-envelope-o"></i>
                                                </div>
                                                <div className="each-info media-body">
                                                    <h4>Email</h4>
                                                    <a href="mailto:yourmail@email.com">yourmail@email.com</a><br/>
                                                    <a href="mailto:yourmail@email.com">yourmail@email.com</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-12 xs-no-padding">
                                            <div className="mh-address-footer-item media dark-bg shadow-1 wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.6s">
                                                <div className="each-icon">
                                                    <i className="fa fa-phone"></i>
                                                </div>
                                                <div className="each-info media-body">
                                                    <h4>Phone</h4>
                                                    <a href="callto:(880)-8976-987">(880)-8976-987</a><br/>
                                                    <a href="callto:(880)-8976-987">(880)-8976-987</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-6 wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.2s">
                                        <form id="contactForm" className="single-form quate-form wow fadeInUp" data-toggle="validator">
                                            <div id="msgSubmit" className="h3 text-center hidden"></div>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <input name="name" className="contact-name form-control" id="name" type="text" placeholder="First Name" required />
                                                </div>
                    
                                                <div className="col-sm-12">
                                                    <input name="name" className="contact-email form-control" id="L_name" type="text" placeholder="Last Name" required />
                                                </div>
                    
                                                <div className="col-sm-12">
                                                    <input name="name" className="contact-subject form-control" id="email" type="email" placeholder="Your Email" required />
                                                </div>
                    
                                                <div className="col-sm-12">
                                                    <textarea className="contact-message" id="message" rows="6" placeholder="Your Message" required></textarea>
                                                </div>
                                                
                                                {/* Subject Button */}
                                                <div className="btn-form col-sm-12">
                                                    <button type="submit" className="btn btn-fill btn-block" id="form-submit">Send Message</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <div className="col-sm-12 mh-copyright wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.2s">
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <div className="text-left text-xs-center">
                                                    <p>All right reserved Siful Islam @ 2018</p>
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <ul className="social-icon">
                                                    <li><a href=""><i className="fa fa-facebook"></i></a></li>
                                                    <li><a href=""><i className="fa fa-twitter"></i></a></li>
                                                    <li><a href=""><i className="fa fa-github"></i></a></li>
                                                    <li><a href=""><i className="fa fa-dribbble"></i></a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
}

export default Profile;
